import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';
import {
  createCustomDocumentTemplates,
  deleteCustomDocumentTemplates,
  fetchCustomTemplate,
  fetchCustomDocumentTemplates,
  updateCustomDocumentTemplates
} from '../../api/v4';

import { getActiveCompany } from '../../selectors/company';

import CustomDocumentCard from '../../components/documentCards/CustomDocumentCard';
import DeleteTemplateModal from '../../components/Modal/deleteTemplateModal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import { isValidTableQuestion } from '../../utils/formHelpers';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function CustomDocumentTemplateContainer() {
  const { templateId, duplicate } = useParams();

  const company = useSelector(getActiveCompany);

  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fields, setFields] = useState([]);
  const [label, setLabel] = useState('');
  const [shouldHaveExpiredDate, setShouldHaveExpiredDate] = useState(false);
  const [isConfidential, setIsConfidential] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [originalLabel, setOriginalLabel] = useState('');
  const [templates, setTemplates] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnsavedTemplateModal, setOpenUnsavedTemplateModal] =
    useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  useEffect(() => {
    fetchCustomDocumentTemplates().then(setTemplates);
    if (templateId) {
      fetchCustomTemplate(templateId).then(response => {
        if (duplicate) {
          response.fields.forEach(f => delete f._id);
          setLabel(`${response.label} - Copy`);
          setOriginalLabel(`${response.label} - Copy`);
          setFields(response?.fields);
          setIsCreating(true);
          setShouldHaveExpiredDate(response?.shouldHaveExpiredDate);
          setIsConfidential(response?.isConfidential);
        } else {
          setLabel(response?.label);
          setOriginalLabel(response.label);
          setFields(response?.fields);
          setIsEditing(true);
          setShouldHaveExpiredDate(response?.shouldHaveExpiredDate);
          setIsConfidential(response?.isConfidential);
          setIsArchived(response?.isArchived);
        }
      });
    } else {
      setIsCreating(true);
      setFields([
        {
          type: '',
          label: '',
          options: [],
          required: false
        }
      ]);
    }
  }, [templateId, duplicate]);

  const handleSubmit = () => {
    const updatedFields = fields.map(field => {
      field.type = field.type.value ?? field.type;
      field.options =
        field.type !== 'table'
          ? field?.options?.map(option => option.option ?? option)
          : field.options;
      return field;
    });

    const payload = {
      label,
      fields: updatedFields,
      shouldHaveExpiredDate,
      companyId: company._id,
      isConfidential,
      isArchived
    };

    if (isCreating) {
      createCustomDocumentTemplates(payload).then(() => history.goBack());
    } else {
      payload._id = templateId;
      updateCustomDocumentTemplates(payload).then(() => history.goBack());
    }
  };

  const handleDelete = () => {
    deleteCustomDocumentTemplates(templateId).then(() => history.goBack());
  };

  const isTitleValid = title => {
    let formattedTitle = title
      .split(' ')
      .filter(s => s)
      .join(' ');

    if (isCreating) {
      return (
        templates.filter(
          t => t.label.toLowerCase() === formattedTitle.toLowerCase()
        ).length === 0
      );
    } else {
      let removedTemplate = templates.filter(
        t => t.label.toLowerCase() !== originalLabel.toLowerCase()
      );
      return (
        removedTemplate.filter(
          t => t.label.toLowerCase() === formattedTitle.toLowerCase()
        ).length === 0
      );
    }
  };

  const handleArchive = () => {
    const updatedFields = fields.map(field => {
      field.type = field.type.value ?? field.type;
      field.options =
        field.type !== 'table'
          ? field?.options?.map(option => option.option ?? option)
          : field.options;
      return field;
    });
    const payload = {
      label,
      fields: updatedFields,
      shouldHaveExpiredDate,
      companyId: company._id,
      isConfidential,
      isArchived: !isArchived
    };
    payload._id = templateId;
    updateCustomDocumentTemplates(payload).then(() => {
      if (!isArchived) history.push('/app/customDocumentTemplateList');
      else {
        fetchCustomTemplate(templateId).then(response => {
          setLabel(response?.label);
          setOriginalLabel(response?.label);
          setFields(response?.fields);
          setShouldHaveExpiredDate(response?.shouldHaveExpiredDate);
          setIsConfidential(response?.isConfidential);
          setIsArchived(response?.isArchived);
        });
      }
    });
  };
  const questionsMissingParts = fields?.filter(
    question =>
      !question.type ||
      !question.label?.trim() ||
      (question.type &&
        ['dropdown', 'multiselect', 'radiobuttons', 'checkbox'].includes(
          question.type.value
        ) &&
        question.options.length === 0) ||
      (question.type.value === 'table' && !isValidTableQuestion(question))
  );

  const canSubmit =
    fields?.length &&
    !questionsMissingParts?.length &&
    isTitleValid(label) &&
    label.trim()?.length;

  const header = (
    <Header
      title="Custom Document"
      section={
        !isEditing && !isCreating
          ? 'View Template'
          : isCreating
            ? 'Create Template'
            : 'Edit Template'
      }
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges && isEditing
          ? setOpenUnsavedChangesModal(true)
          : hasUnsavedChanges && isCreating
            ? setOpenUnsavedTemplateModal(true)
            : history.goBack()
      }
      pageActionOptions={
        !isCreating
          ? [
              {
                visible: true,
                label: `${isEditing ? 'Delete' : 'Edit'} Template`,
                color: isEditing ? 'redOutline' : 'blueOutline',
                onClick: () =>
                  isEditing ? setOpenDeleteModal(true) : setIsEditing(true)
              },
              {
                visible: true,
                label: `${isArchived ? 'Un-' : ''}Archive Template`,
                color: 'blueOutline',
                onClick: () => handleArchive()
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonDisabled={isEditing && !hasUnsavedChanges}
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        isEditing && !hasUnsavedChanges
          ? setIsEditing(false)
          : setOpenUnsavedChangesModal(true)
      }
      editing={isEditing}
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={(isCreating || isEditing) && !isArchived}
      >
        <CustomDocumentCard
          onChange={fields => {
            setFields(fields);
            setHasUnsavedChanges(true);
          }}
          handleTitleChange={title => {
            setLabel(title);
            setHasUnsavedChanges(true);
          }}
          handleExpiredChange={checked => {
            setShouldHaveExpiredDate(checked);
            setHasUnsavedChanges(true);
          }}
          handleConfidentialChange={checked => {
            setIsConfidential(checked);
            setHasUnsavedChanges(true);
          }}
          label={label}
          shouldHaveExpiredDate={shouldHaveExpiredDate}
          disabled={(!isEditing && !isCreating) || isArchived}
          fields={fields?.filter(f => !f?.legacy)}
          isEditing={isEditing}
          duplicating={duplicate}
          isConfidential={isConfidential}
          missingRequired={missingRequired}
          isTitleValid={isTitleValid(label)}
        />
      </HeaderAndFooter>
      <Modal
        title="Unsaved Template"
        titleClassName="redHeader"
        isOpen={openUnsavedTemplateModal}
        submitButtonColor="red"
        submitButtonText="Discard Template"
        onRequestClose={() => setOpenUnsavedTemplateModal(false)}
        submitActions={() => history.goBack()}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          You are navigating away from a document template that you were
          creating.{' '}
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Leaving without saving will result in this template not being
            created.{' '}
          </span>
          If you wish to keep your template, click “Cancel”, and then hit “Save
          Changes” at the bottom of the create template screen.
        </div>
      </Modal>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => openUnsavedChangesModal(false)}
        submitActions={() => history.goBack()}
        savingWhat="a document template"
      />
      <DeleteTemplateModal
        isOpen={openDeleteModal}
        deletingWhat="Custom Document"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() => handleDelete()}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Custom Document Template"
        isEditing={isEditing}
      />
    </>
  );
}
