import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import history from '../../../history';

import { getActiveCompany } from '../../../selectors/company';

import {
  checkJobTitleGroupPairs,
  createTrainingCourse,
  fetchCompanyUsers,
  fetchDocumentFoldersByType,
  getCourseTemplates,
  getJobList
} from '../../../api/v4';
import { TRAINING_TYPES } from '../../../constants/constants';

import {
  canSubmitCourse,
  isByEmployeeValid,
  isByJobTitleValid,
  getValidAssignees
} from '../../../utils/trainingHelper';
import { getSingleCompanyGroupDropdown } from '../../../utils/employeeDGPHelper';

import {
  Button,
  Checkbox,
  Dropdown,
  EmployeeDropdown,
  InputRow,
  Textbox,
  TwoColumn
} from '../../../components/inputs';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import HierarchySelector from '../../../components/HierarchySelector';
import { IReportDatePicker } from '../../../components/inputs/DateTimePicker';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { Rearranger } from '../../../components/Rearranger';
import ReassignUserModal from '../../../components/Modal/reassignUserModal';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from './styles.module.scss';

export default function TrainingCourseCreate() {
  const company = useSelector(getActiveCompany);
  const location = useLocation();
  const fromScreen = location.state?.fromScreen;

  const emptyCourse = {
    folderId: null,
    groupIds: [],
    projectIds: [],
    title: '',
    instructions: '',
    sequential: false,
    assignOn: null,
    dueDate: null,
    trainings: [],
    assignedTo: []
  };

  const [course, setCourse] = useState(emptyCourse);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [jobList, setJobList] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [openUnsavedModal, setOpenUnsavedModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [approverModal, setApproverModal] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [selectedApprover, setSelectedApprover] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [jobTitleModal, setJobTitleModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);

  const groupDropdown = getSingleCompanyGroupDropdown(company);

  useEffect(() => {
    getCourseTemplates().then(r =>
      setTemplates(
        [{ label: '', value: null }].concat(
          r.map(temp => ({ ...temp, value: temp._id, label: temp.title }))
        )
      )
    );

    fetchDocumentFoldersByType('TrainingCourses').then(r =>
      setFolders(
        [{ value: null, label: ' ' }].concat(
          r.map(f => {
            return { value: f._id, label: f.name };
          })
        )
      )
    );

    getJobList().then(r => {
      setJobList(r?.map(job => ({ value: job, label: job })));
    });

    fetchCompanyUsers().then(setUsers);
  }, []);

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem('trainingCourse'));
    if (local) {
      setUnsavedChanges(location.state?.newTraining?.title);
      setUnsavedChanges(true);
      const trainingIndex = local.trainings?.findIndex(
        t =>
          (t._id ?? t.trainingTemplateId) ===
          (location.state?.oldTemplate ?? location.state?.newTraining?._id)
      );
      let newTrainings =
        local.trainings?.filter(
          t =>
            (t._id ?? t.trainingTemplateId) !==
            (location.state?.oldTemplate ?? location.state?.newTraining?._id)
        ) ?? [];

      if (trainingIndex > -1)
        newTrainings = [
          ...newTrainings.slice(0, trainingIndex),
          location.state?.newTraining,
          ...newTrainings.slice(trainingIndex)
        ];
      else
        newTrainings = newTrainings.concat(location.state?.newTraining ?? []);
      setCourse({
        ...local,
        trainings: newTrainings
      });
      delete location.state?.newTraining;
      localStorage.removeItem('trainingCourse');
    }
  }, [location.state]);

  const handleBack = () => {
    history.push(fromScreen ?? '/app/trainingCourses');
  };

  const handleSubmit = () => {
    if (course?.assignBy === 1) {
      checkJobTitleGroupPairs(course).then(r => {
        if (r?.length) {
          setJobTitleModal(true);
          setEmployees(r);
        } else {
          createTrainingCourse(course).then(handleBack);
        }
      });
    } else createTrainingCourse(course).then(handleBack);
  };

  const handleAnswer = answer => {
    setUnsavedChanges(true);
    setCourse({ ...course, ...answer });
  };

  const trainingBox = training => {
    const needsApprover = training.needsApproval && !training.approver;
    return (
      <div
        className={styles.trainingText}
        onClick={() => {
          if (training.needsApproval) {
            setApproverModal(true);
            setSelectedApprover(training.approver);
            setSelectedTraining(training);
          }
        }}
      >
        <p style={{ fontWeight: 'bold' }}>{training.title}</p>
        <p>
          {TRAINING_TYPES.find(t => t.value === training.type)?.label ??
            'Digital Training'}
        </p>
        {training.needsApproval ? (
          needsApprover ? (
            <p className={styles.errorText}>Additional Information Required</p>
          ) : (
            <p className={styles.linkText}>Change Approver</p>
          )
        ) : null}
      </div>
    );
  };

  const renderByJobTitle = () => {
    const groupsSelected = course?.groupAssigneePairs?.map(g => g.groupId);
    return (
      <>
        <div className={styles.adminContainer}>
          <span className={styles.adminInfo}>
            One course will be created for each group/establishment selected
          </span>
        </div>
        {course.groupAssigneePairs?.map((group, index) => {
          return (
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <HierarchySelector
                  groupId={group?.groupId}
                  filteredGroupDropdown={groupDropdown}
                  onGroupChange={groupId => {
                    let newGroup = {
                      ...course.groupAssigneePairs[index],
                      groupId
                    };
                    course.groupAssigneePairs[index] = newGroup;
                    handleAnswer({
                      groupAssigneePairs: course.groupAssigneePairs
                    });
                  }}
                  showProject={false}
                  noTwoColumn
                  groupTouched={missingRequired && !group?.groupId}
                  excludeGroups={groupsSelected?.filter(
                    g => g !== group.groupId
                  )}
                  alwaysShowGroupDropdown
                />

                <Dropdown
                  selectButtons
                  fieldLabel="Job Title(s)"
                  options={jobList}
                  searchable
                  onChange={jobTitles => {
                    let newTitles = {
                      ...course.groupAssigneePairs[index],
                      jobTitles
                    };
                    course.groupAssigneePairs[index] = { ...newTitles };
                    handleAnswer({
                      groupAssigneePairs: course.groupAssigneePairs
                    });
                  }}
                  currentValue={group.jobTitles}
                  isRequired
                  multi
                  touched={missingRequired && !group?.jobTitles?.length}
                />
              </TwoColumn>
              {course.groupAssigneePairs?.length > 1 && (
                <img
                  type="button"
                  s
                  src={require('../../../assets/images/remove.png')}
                  alt="delete group"
                  onClick={() => {
                    course.groupAssigneePairs.splice(index, 1);
                    handleAnswer({
                      groupAssigneePairs: course.groupAssigneePairs
                    });
                  }}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              )}
            </TwoColumn>
          );
        })}
        <Button
          text="Add Group"
          color={
            missingRequired && !course?.groupAssigneePairs?.length
              ? 'red'
              : 'blue'
          }
          onClick={() =>
            handleAnswer({
              groupAssigneePairs: (course.groupAssigneePairs ?? []).concat({})
            })
          }
          inputClassName={styles.button}
        />
      </>
    );
  };

  const renderByEmployee = () => {
    const groupsSelected = course?.groupAssigneePairs?.map(g => g.groupId);
    return (
      <>
        <div className={styles.adminContainer}>
          <span className={styles.adminInfo}>
            One course will be created for each group/establishment selected
          </span>
        </div>
        {course.groupAssigneePairs?.map((group, index) => {
          return (
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <HierarchySelector
                  groupId={group?.groupId}
                  onGroupChange={groupId => {
                    let newGroup = {
                      ...course.groupAssigneePairs[index],
                      groupId
                    };

                    newGroup.assignees = getValidAssignees(newGroup, users);
                    course.groupAssigneePairs[index] = newGroup;
                    handleAnswer({
                      groupAssigneePairs: course.groupAssigneePairs
                    });
                  }}
                  groupDisabled={course.equipmentId}
                  showProject={false}
                  noTwoColumn
                  groupTouched={missingRequired && !group?.groupId}
                  excludeGroups={groupsSelected?.filter(
                    g => g !== group.groupId
                  )}
                  alwaysShowGroupDropdown
                />

                <EmployeeDropdown
                  permissions={[100, 400, 500, 900]}
                  fieldLabel="Assignee(s)"
                  currentValue={group.assignees}
                  onChange={assignees => {
                    let newAdmin = {
                      ...course.groupAssigneePairs[index],
                      assignees
                    };
                    course.groupAssigneePairs[index] = { ...newAdmin };
                    handleAnswer({
                      groupAssigneePairs: course.groupAssigneePairs
                    });
                  }}
                  searchable
                  name="dropdownEmployees"
                  isRequired
                  multi
                  touched={missingRequired && !group?.assignees?.length}
                  group={group?.groupId}
                />
              </TwoColumn>
              {course.groupAssigneePairs?.length > 1 && (
                <img
                  type="button"
                  src={require('../../../assets/images/remove.png')}
                  alt="delete group"
                  onClick={() => {
                    course.groupAssigneePairs.splice(index, 1);
                    handleAnswer({
                      groupAssigneePairs: course.groupAssigneePairs
                    });
                  }}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              )}
            </TwoColumn>
          );
        })}
        <Button
          text="Add Group"
          color={
            missingRequired && !course?.groupAssigneePairs?.length
              ? 'red'
              : 'blue'
          }
          onClick={() =>
            handleAnswer({
              groupAssigneePairs: (course.groupAssigneePairs ?? []).concat({})
            })
          }
          inputClassName={styles.button}
        />
      </>
    );
  };

  const header = (
    <Header
      title="Create Training Course"
      needsSaved={hasUnsavedChanges}
      section="Training Course"
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedModal(true) : handleBack()
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        canSubmitCourse(course) ? handleSubmit() : setMissingModalOpen(true)
      }
      cancelButtonClick={() =>
        hasUnsavedChanges ? setOpenUnsavedModal(true) : handleBack()
      }
      onMouseEnter={() => setMissingRequired(!canSubmitCourse(course))}
    />
  );

  const canAddTrainings =
    course.title?.trim() &&
    course.assignOn &&
    course.dueDate &&
    (course.assignBy === 1
      ? isByJobTitleValid(course)
      : isByEmployeeValid(course));

  const allEmpsHaveSelectedGroups = employees.every(e => e.selectedGroupId);

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer} showFooter>
        <Card>
          <Dropdown
            options={templates?.map(t => ({
              ...t,
              isVisible: !t.isArchived
            }))}
            fieldLabel="Course Template"
            onChange={v => {
              const foundTemplate = templates.find(t => t._id === v);
              handleAnswer({
                ...emptyCourse,
                ...(foundTemplate ?? {}),
                disabledTrainings: foundTemplate?.trainings
                  ?.filter(t => !t.nscCompanyIds?.length)
                  ?.map(t => t._id ?? t),
                nonDeletable: foundTemplate?.trainings?.map(t => t._id ?? t),
                masterTemplate: v,
                _id: null
              });
            }}
            currentValue={course.masterTemplate}
            searchable
          />
          <Dropdown
            options={folders}
            fieldLabel="Folder"
            onChange={v => handleAnswer({ folderId: v })}
            currentValue={course.folderId}
            searchable
          />
          <Textbox
            isRequired
            fieldLabel="Training Course Title"
            currentValue={course.title}
            disabled={course.masterTemplate}
            onChange={v => handleAnswer({ title: v })}
            touched={missingRequired && !course.title}
          />
          <Textbox
            fieldLabel="Instructions"
            type="textarea"
            onChange={v => handleAnswer({ instructions: v })}
            currentValue={course.instructions}
          />
          <Checkbox
            fieldLabel="Release Sequentially"
            currentValue={course.sequential}
            onChange={v => handleAnswer({ sequential: v })}
            disabled={course.masterTemplate}
            tooltip="If selected, the trainings will release one after the other. Otherwise trainings will release all at once."
            displayTooltip
          />
          {/* hide until course creating all the trainings is addressed (https://gitlab.com/ireportsource/ireportsource-react-app/-/issues/1162#note_1859805001)*/}
          {/* <Checkbox
            fieldLabel="Immediately Reassign if Assignee(s) fail?"
            currentValue={course.immediatelyReassign}
            displayTooltip
            disabled={course.masterTemplate}
            tooltip={
              <div>
                <p>
                  When this is selected, any assignee that fails this course
                  will be automatically reassigned this course.
                </p>
                <p>
                  This course will be reassigned after everyone has completed
                  the course.
                </p>
              </div>
            }
            onChange={value =>
              handleAnswer({
                immediatelyReassign: value
              })
            }
          /> */}
          <IReportDatePicker
            fieldLabel="Assign On"
            isRequired
            onChange={v =>
              handleAnswer({
                assignOn: v,
                dueDate: moment(v).isAfter(course.dueDate)
                  ? null
                  : course.dueDate
              })
            }
            currentValue={course.assignOn}
            name="assignOn"
            minDate={new Date()}
            touched={missingRequired && !course.assignOn}
          />
          <IReportDatePicker
            fieldLabel="Due By"
            isRequired
            onChange={v => handleAnswer({ dueDate: v })}
            currentValue={course.dueDate}
            disabled={!course.assignOn}
            name="dueDate"
            minDate={new Date(moment(course.assignOn)?.add(1, 'days'))}
            touched={missingRequired && !course.dueDate && course.assignOn}
          />
          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={course.assignBy}
            onChange={v => {
              handleAnswer({
                assignBy: v,
                assignedTo: [],
                assignByJobTitles: []
              });
            }}
            fieldLabel="Assign By"
            needSeparateLines
            isRequired
            touched={missingRequired && !course.assignBy}
          />
          {course?.assignBy === 1
            ? renderByJobTitle()
            : course?.assignBy === 2
              ? renderByEmployee()
              : null}
        </Card>

        <InputRow className={styles.overallFooter}>
          <Rearranger
            className={styles.trainingCards}
            itemStyles={styles.trainingCard}
            items={course.trainingTemplates ?? course?.trainings ?? []}
            onChange={newTrainings => {
              setUnsavedChanges(true);
              handleAnswer(
                course.trainingTemplates
                  ? { trainingTemplates: [...newTrainings] }
                  : { trainings: [...newTrainings] }
              );
            }}
            renderItem={trainingBox}
            disabledItems={course.disabledTrainings}
            nonDeletableItems={course.nonDeletable}
            errorItems={course.trainings
              .filter(t => t.needsApproval && !t.approver)
              .map(t => t._id)}
            onDuplicate={row => {
              const clone = cloneDeep(row);
              delete clone._id;
              return clone;
            }}
            inline
            hideDuplicate
            allowEdit
            handleEditRow={template => {
              localStorage.setItem('trainingCourse', JSON.stringify(course));
              localStorage.setItem(
                'trainingTemplate',
                JSON.stringify({
                  ...template,
                  stage: template.nscCompanyIds?.length ? 1 : 0
                })
              );
              history.push({
                pathname: `/app/trainingCourses/addTraining`,
                state: {
                  fromScreen: `/app/trainingCourses/create`,
                  creating: true,
                  excludeTemplates: course.trainings
                    .map(t => t._id ?? t.trainingTemplateId ?? t)
                    .filter(
                      t =>
                        t !==
                        (
                          template._id ??
                          template.trainingTemplateId ??
                          template
                        ).toString()
                    ),
                  invalidNames: course.trainings
                    .filter(t => t.title !== template.title)
                    .map(t => t.title.toLowerCase().trim())
                }
              });
            }}
          />
          <Button
            color="blue"
            text="Add Training"
            onClick={() => {
              localStorage.setItem('trainingCourse', JSON.stringify(course));
              history.push({
                pathname: `/app/trainingCourses/addTraining`,
                state: {
                  fromScreen: `/app/trainingCourses/create`,
                  excludeTemplates: course.trainings.map(
                    t => t._id ?? t.trainingTemplateId ?? t
                  ),
                  invalidNames: course.trainings.map(t =>
                    t.title.toLowerCase().trim()
                  ),
                  creating: true
                }
              });
            }}
            disabled={!canAddTrainings}
            inputClassName={
              missingRequired && canAddTrainings
                ? styles.trainingRequiredButton
                : ''
            }
          />
        </InputRow>
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedModal}
        savingWhat="Training Course"
        onRequestClose={() => setOpenUnsavedModal(false)}
        submitActions={handleBack}
      />
      <ReassignUserModal
        title="Change Approver"
        reassignUser={selectedApprover}
        disableSubmit={!selectedApprover}
        titleClassName="redHeader"
        previousUser={selectedTraining?.approver}
        message="This course training requires approval, and needs an approver to be
            selected."
        onRequestClose={() => {
          setApproverModal(false);
          setSelectedApprover(null);
        }}
        isOpen={approverModal}
        onChange={setSelectedApprover}
        submitActions={() => {
          const found = course.trainings.findIndex(
            t => t._id === selectedTraining?._id
          );
          course.trainings[found].approver = selectedApprover;
          setApproverModal(false);
          setSelectedApprover(null);
        }}
        permissions={[500, 900]}
        placeholder="Select one.."
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        subject="Training Course"
        onRequestClose={() => setMissingModalOpen(false)}
      />
      <Modal
        title="Choose Group"
        titleClassName="redHeader"
        isOpen={jobTitleModal}
        submitButtonColor="green"
        onRequestClose={() => setJobTitleModal(false)}
        submitActions={() =>
          allEmpsHaveSelectedGroups
            ? createTrainingCourse({
                ...course,
                selectedEmpGroup: employees
              }).then(handleBack)
            : null
        }
        submitTooltip={
          allEmpsHaveSelectedGroups
            ? null
            : 'All Employees must have a Group or Establishment selected.'
        }
      >
        <>
          <b>
            The following users are a part of multiple groups selected. Please
            choose which group you would like their course assigned in.
          </b>
          {employees?.map((e, i) => {
            const groupDropdown = company?.groups
              ?.filter(g => e?.groupIds?.includes(g._id))
              ?.map(g => ({ label: g.name, value: g._id }));
            return (
              <TwoColumn>
                <EmployeeDropdown
                  fieldLabel="Employee"
                  currentValue={e?._id}
                  searchable
                  name="dropdownEmployees"
                  isRequired
                  disabled
                />
                <HierarchySelector
                  groupId={employees[i]?.selectedGroupId}
                  filteredGroupDropdown={groupDropdown}
                  showProject={false}
                  noTwoColumn
                  onGroupChange={groupId => {
                    let updated = { ...employees[i], selectedGroupId: groupId };
                    setEmployees(employees.toSpliced(i, 1, updated));
                  }}
                />
              </TwoColumn>
            );
          })}
        </>
      </Modal>
    </>
  );
}
